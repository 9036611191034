
export enum CmsIds {
    Term = 96930,
    Policy = 96932,
    BySignupTermAndPolicy = 96927,
    AffiliateAgrement = 66292,
    AgreeToTermsAndCond = 76270,
    SomethingWrong = 66587,
    RecieveUpdateText  = 85158,
    CreateAccount = 66302,
    PleaseAcknowledge = 66550,
    ThanksForBePartner = 84128,
    CoporatePleaseClickLink = 84130,
    RedirectToSignin = 277303
}
