import { CmsIds } from "./CmsIds";

export const DefaultCms: Cms.CmsDictionary =  {
    [CmsIds.AgreeToTermsAndCond]: "I agree to the terms and conditions in the {0}",
    [CmsIds.RecieveUpdateText]: "I would like to receive updates and learn about new features and products by email. I understand I may unsubscribe at any time.",
    [CmsIds.AffiliateAgrement]: "Affiliate Agreement",
    [CmsIds.ThanksForBePartner]: "Thank you for becoming an Agoda Affiliate Partner. Please click on the link we've emailed you to confirm your email address. Note that this is mandatory for earning commissions. ",
    [CmsIds.PleaseAcknowledge]: 'Please acknowledge that you understand and consent to our affiliate agreement.',
    [CmsIds.SomethingWrong]: 'Oops! <br />Seems we are having some technical problems. <br />Please check back in a few minutes.',
    [CmsIds.BySignupTermAndPolicy]: 'By signing up, you accept our {0}, and {1}.',
    [CmsIds.Term]: 'Terms of Use',
    [CmsIds.Policy]: 'Privacy & Cookies Statement',
    [CmsIds.CreateAccount]: 'Create account',
}