import React, { FC } from "react";
import { Logo } from "@kite-js/logo";
import { SymbolCircleUserLineIcon } from '@kite-js/icons-remastered';
import { Link } from 'react-router-dom';
import { MainMenu, Nav, NavContainer, SignInButton } from './Navbar.styled';
import { useIsMobile } from '../../../hooks/useIsMobile';

interface NavbarProps {
    cmsItems: Cms.CmsDictionary
}

const Navbar: FC<NavbarProps> = ({ cmsItems }) => {
    const isMobile = useIsMobile()
    return (
        <Nav>
            <NavContainer>
                <Link to="/"><Logo name="agoda-white" width={isMobile ? 70 : 100} mx={"XL"} /></Link>
                <MainMenu>
                    <li className="property-owner">
                        <a 
                            href="https://ycs.agoda.com/homes?cid=1541694" 
                            className="traffic-tracking"
                            target="_blank" rel="noreferrer">
                            <i className="ficon ficon-avatar-property ficon-24" />
                            {cmsItems?.[83482] ?? "Home Owner?"}
                        </a>
                    </li>
                    <li>
                        <SignInButton to="/signin">
                            <SymbolCircleUserLineIcon size="XXL" color="inherit" style={{ fill: "white"}}/>
                            <span>{cmsItems?.[65653] ?? "Sign In"}</span>
                        </SignInButton>
                    </li>
                </MainMenu>
            </NavContainer>
        </Nav>
    );
};

export default Navbar;
