import React, { FC, useState } from "react"
import UniversalSignUp from "../../Common/UniversalSignup/UniversalSignup";
import Navbar from "../../Common/Navbar/Navbar";
import { LoginSuccessResponseStatus, SDKConfig } from "@ul/sdk";
import { UserPreference, LoginSucessAdditional } from "@ul/common"
import { DefaultUL } from "../../../constants/common/DefaultULSdk";
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useSearchParams } from 'react-router-dom';
import { parseSignUpType } from '../../../utils/getSignUpType';
import { log } from "../../../utils/logUtils";
import { ULContainer } from "../../Common/Layout/ULContainer.styled";
import ThankyouSignupModal from "../../Common/SignupSuccess/SignupSuccess";
import signup from "../../Services/ajaxService";
import { PageType } from "../../../constants/common/pageType";

interface SignUpProps {
    locale?: string
    cmsItems: Cms.CmsDictionary
}

const getStatus = (status: number) => {
    switch (status) {
        case 200:
            return LoginSuccessResponseStatus.Success;
        case 403:
        case 401:
            return LoginSuccessResponseStatus.Fail;
        default:
            return LoginSuccessResponseStatus.Error;
    }
};

const SignUp: FC<SignUpProps> = ({ cmsItems, locale }) => {
    const isMobile = useIsMobile();
    const [searchParam] = useSearchParams()
    const [signupSuccess, setSignupSuccess] = useState<boolean>(false);
    const [aid, setAid] = useState<number>(0);
    const signUpType = parseSignUpType(searchParam.get("psrc"))
    const sdkConfig: SDKConfig = React.useMemo(() => ({
        ...DefaultUL,
        path: 'signup',
        cultureCode: locale ?? "en-us",
        iframe: {
            style: {
                'min-width': isMobile ? "315px" : "500px",
                'padding': "0",
                'background': "white",
                "border-radius": "20px"
            },
        },
        eventHandlers: {
            onLoginSuccess: async (_additional?: LoginSucessAdditional) => {
                log('onLoginSuccess');
                const result = await signup(signUpType, _additional?.isSubscribeToPCEmail ?? false, PageType.SignUp);
                const status = getStatus(result.status);
                if (status === LoginSuccessResponseStatus.Success) {
                    setSignupSuccess(true);
                    if (result.affiliateId) setAid(result.affiliateId);
                }
                return {
                    loginStatus: status,
                };
            },
            onLoginComplete: (_success: boolean, _userPreference?: UserPreference) => {
                return 0;
            },
        },
        partnerCenterConfig: {
            signUpType,
        },
    }), [locale, signUpType])
    return <ULContainer>
        <Navbar cmsItems={cmsItems} />
        <UniversalSignUp sdkConfig={sdkConfig} />
        <ThankyouSignupModal open={signupSuccess} cmsItems={cmsItems} signupType={signUpType} aid={aid}/>
    </ULContainer>
}

export default SignUp