type Keys = string | number;
type Values = Keys | null | undefined;
type ValuesJsx = Values | JSX.Element | JSX.Element[];
export type ValuesObject = { [K in Keys]: Values } | Values[];
export type ValuesObjectJsx = { [K in Keys]: ValuesJsx } | ValuesJsx[];
export const formatMessage = (message: string, values?: ValuesObject): string => {
    return values
        ? Object.keys(values).reduce((formattedMessage, x) => formattedMessage.replace(new RegExp(`\\{${x}\\}`, 'g'), () => values[x]), message)
        : message;
};

export const formatMessageJsx = (message: string, values: ValuesObjectJsx): string | JSX.Element[] => {
    return values && Object.keys(values).length && message
        ? message.split(/[\{\}]/).map(x => x in values ? values[x] : x) //eslint-disable-line
        : message;
};