import styled from 'styled-components';

export const ULSignUpContainer = styled.div`
  display: flex;
  justify-content: center;
    padding-top: 20px;
    min-height: 900px;
    background-color: #4b80f3;
    background: -webkit-gradient(linear,left top,right top,from(#4b80f3),to(#8c69ca));
    background: linear-gradient(to right,#4b80f3,#8c69ca);
`;