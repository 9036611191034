import React, { useState} from "react";
import UniversalLoginPageComponent from "../../Common/UniversalLogin/UniversalLogin";
import { Box, Button, Divider, GridV2 } from "@kite-js/core";
import illustrationCityAffiliate from "../../../images/illustration-city-affiliate.png";
import './SignIn.scss';
import { log } from "../../Utils/logUtils";
import { LoginSuccessResponseStatus, SDKConfig } from "@ul/sdk";
import { DefaultUL } from "../../../constants/common/DefaultULSdk";
import { useSearchParams } from "react-router-dom";
import { parseSignUpType } from "../../../utils/getSignUpType";
import { SignUpTypes } from "../../../constants/common/signUpType";

interface SignInProps {
    cmsItems: Cms.CmsDictionary;
    locale: string;
    enableOnboarding: boolean;
}

const SignIn: React.FC<SignInProps> = (props: SignInProps) => {
    const [cmsList] = useState<Cms.CmsDictionary>(props?.cmsItems ?? []);
    const [locale] = useState<string>(props.locale ?? "en-us");
    const [searchParam] = useSearchParams()
    const allQs = searchParam.toString();
    const signUpType = parseSignUpType(searchParam.get("psrc"))

    const getCmsText = (cmsId: number, defaultText: string) => {
        return cmsList[cmsId] ?? defaultText
    }
    
    const handleSignUpClick = async () => {
        try{
            window.location.href = "/signup";
        }
        catch (e) {
            throw new Error("Cannot navigate to signup page");
        }
    }

    const getStatus = (status: number, enableOnboarding: boolean,signupType: SignUpTypes) => {
        switch (status) {
            case 200:
                return LoginSuccessResponseStatus.Success;
            case 403:
                if (enableOnboarding && signupType != SignUpTypes.Enterprise) return LoginSuccessResponseStatus.NotImplemented;
                return LoginSuccessResponseStatus.Fail;
            case 401:
                return LoginSuccessResponseStatus.Fail;
            default:
                return LoginSuccessResponseStatus.Error;
        }
    };
    
    const sdkConfig: SDKConfig = {
        ...DefaultUL,
        cultureCode: locale,
        eventHandlers: {
            onLoginSuccess: async () => {
                log('onLoginSuccess');
                const result = await fetch('/api/LoginWithToken', { credentials: 'same-origin' })
                    .then(response => response.json());
                if (props.enableOnboarding && result.status === 403 && signUpType !== SignUpTypes.Enterprise) {
                    window.location.href = '/onboarding?'+allQs;
                }    
                if (result.redirectUrl) window.location.href = result.redirectUrl;
                return {
                    loginStatus: getStatus(result.status, props.enableOnboarding, signUpType),
                    redirectUrl: result.redirectUrl
                };
            },
            onLoginComplete: (success: boolean) => {
                log('onLoginComplete'+ success);
            },
        }
    };
    
    return (
        <Box shadow="M" radius="M" style={{ margin: 'auto', marginTop: '10%' }} width="1000px" className="signin-container" data-testid="signin-container">
            <GridV2>
                <GridV2.Item sm={6} md={6}>
                    <GridV2 direction="column">
                        <GridV2.Item>
                            <UniversalLoginPageComponent sdkConfig={sdkConfig}/>
                        </GridV2.Item>
                        <Divider />
                        <GridV2.Item>
                            <div>
                                {getCmsText(65652, "No Account Yet?")}
                            </div>
                            <Button variant="outlined" fullWidth={true} m="XXL" onClick={handleSignUpClick}>
                                {getCmsText(65040, "Create an account")}
                            </Button>
                        </GridV2.Item>
                    </GridV2>
                </GridV2.Item>
                <GridV2.Item sm={6} md={6}>
                    <div className="presentation-section">
                        <img style={{ marginTop: '25%' }} src={illustrationCityAffiliate} />
                        <div className="presentation-section-text">
                            <h3>{getCmsText(76525, "Become an affiliate")}</h3>
                            <p dangerouslySetInnerHTML={{
                                __html: getCmsText(65029, "\
                                Earn up to 60% commission! \
                                We're growing fast, and so can your earnings.  \
                                Join our affiliate program today. \
                                ")
                            }} />
                        </div>
                    </div>
                </GridV2.Item>
            </GridV2>
        </Box>
    );
}

export default SignIn;