import React, { FC } from "react"
import { ULContainer } from "../../Common/Layout/ULContainer.styled";
import Navbar from "../../Common/Navbar/Navbar";
import { Box, Button, Heading, Link, List } from "@kite-js/core";
import { parseSignUpType } from "../../../utils/getSignUpType";
import signup from "../../Services/ajaxService";
import { useIsMobile } from "../../../hooks/useIsMobile";
import SignupSuccess from "../../Common/SignupSuccess/SignupSuccess";
import { getSearchParam } from "../../Utils/getSearchParam";
import { PageType } from "../../../constants/common/pageType";
import { CheckBoxMargin, SpanMargin } from "../../Common/CheckBoxMargin/CheckBoxMargin.styled";
import { SignUpTypes } from "../../../constants/common/signUpType";
import CommonModal from "../../Common/CommonModal/CommonModal";
import SignupTermAndPoicy from "../../Common/SignupTermAndPolicy/SignupTermAndPolicy";
import { formatMessageJsx } from "../../../utils/formatMessage";
import { CmsIds } from "../../../constants/common/CmsIds";

interface Props {
    cmsItems: Cms.CmsDictionary
}

const OnBoarding: FC<Props> = ({ cmsItems }) => {
    const isMobile = useIsMobile();
    const [emailSubscription, setEmailSubscription] = React.useState<boolean>(false);
    const [agreeTerm, setAgreeTerm] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [signupSuccess, setSignupSuccess] = React.useState<boolean>(false);
    const [signupError, setSignupError] = React.useState<boolean>(false);
    const searchParam = getSearchParam();
    const signUpType = parseSignUpType(searchParam().get("psrc"))
    const submitSignup = async () => { 
        if (!agreeTerm) {
            setErrorMessage(cmsItems[CmsIds.PleaseAcknowledge])
            return;
        }

        const result = await signup(signUpType, emailSubscription, PageType.OnBoarding);
        if (result.status == 200) {
            setSignupSuccess(true);
            setSignupError(false);
        } else {
            setSignupError(true);
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailSubscription(event.target.checked);
    }

    const handleAgreement = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeTerm(event.target.checked);
        if (event.target.checked) setErrorMessage("");
    }

    const agreement = () => {
        const affiliateAgreementUrl =
            signUpType === SignUpTypes.Corporate
                ? 'https://partners.agoda.com/Content/pdf/Agoda_Business_Travel_Program_Agreement.pdf'
                : 'https://partners.agoda.com/Content/pdf/Agoda_OnlineAffiliateAgreement.pdf';
        return formatMessageJsx(
            cmsItems[CmsIds.AgreeToTermsAndCond],
            [<Link key="agrrement" href={affiliateAgreementUrl} target="_blank">
                {cmsItems[CmsIds.AffiliateAgrement]}
            </Link>]);
    }

    return <ULContainer>
        <Navbar cmsItems={cmsItems} />
        <Box shadow="M" radius="M" p="L" style={{ margin: 'auto', marginTop: '10%', minWidth: isMobile ? "315px" : "500px" }} width="60%" background={0}>
            <Heading variant="h3" strong={true}>Onboarding</Heading>
            <Box p="M">
                <List variant="vertical">
                    <CheckBoxMargin name="agreement" onChange={handleAgreement} data-testid="onboard-agreement">
                        <SpanMargin>{agreement()}</SpanMargin>
                    </CheckBoxMargin>
                    <CheckBoxMargin name="subscription" onChange={handleEmailChange} errors={errorMessage} touched={true}>
                        <SpanMargin>{cmsItems[CmsIds.RecieveUpdateText]}</SpanMargin>
                    </CheckBoxMargin>
                </List>
            </Box>
            <Box p="M">
                <Button onClick={submitSignup} data-testid="onboard-submit" >{cmsItems[CmsIds.CreateAccount]}</Button>
            </Box>
            <SignupTermAndPoicy cmsItems={cmsItems}/>
        </Box>
        <SignupSuccess open={signupSuccess} cmsItems={cmsItems} signupType={signUpType} aid={0}/>
        <CommonModal open={signupError} testId="error-modal">
            <Box p="XL">
                <p dangerouslySetInnerHTML={{ __html: cmsItems[CmsIds.SomethingWrong]}} />
            </Box>
        </CommonModal>
    </ULContainer>
}

export default OnBoarding