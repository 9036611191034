import React from "react"
import { SignUpTypes } from "../../../constants/common/signUpType"
import { Box, Button, Heading, Paragraph } from "@kite-js/core";
import CommonModal from "../CommonModal/CommonModal";
import { CmsIds } from "../../../constants/common/CmsIds";

interface Props {
    signupType: SignUpTypes;
    cmsItems: Cms.CmsDictionary;
    open: boolean;
    aid: number;
}

const SignupSuccess: React.FC<Props> = (props: Props) => {
    const msg = (signupType: SignUpTypes) => {
        switch (signupType) {
            case SignUpTypes.Enterprise:
                return ["Thank you for signing up to become an Agoda Affiliate Partner."];
            case SignUpTypes.Corporate:
                return [props.cmsItems?.[CmsIds.CoporatePleaseClickLink], props.cmsItems?.[CmsIds.RedirectToSignin]]
            default:
                return [props.cmsItems?.[CmsIds.ThanksForBePartner], props.cmsItems?.[CmsIds.RedirectToSignin]]
        }
    };
    const redirectToSignin = () => {
        setTimeout(() => {
            window.location.href = '/signin';
        }, 5 * 1000);
    }
    React.useEffect(() => {
        if (props.open && props.signupType != SignUpTypes.Enterprise) {
            redirectToSignin();
        }
    }, [props.signupType, props.open]);

    return <CommonModal open={props.open} testId="thankyou-modal">
        <Box p="XL">
            {msg(props.signupType).map((item: string, index: number) => (<Heading variant="h4"  key={index}>{item}</Heading  >))}
            {props.aid > 0 && <Paragraph data-testid="affiliate-id"><Heading variant="h4" key="aid">Affilate ID: {props.aid}</Heading><Button variant="outlined" fullWidth={true} m="XXL" onClick={redirectToSignin}>OK</Button></Paragraph>}
        </Box>
    </CommonModal>
}

export default SignupSuccess;