import { FormCheckbox, Span } from "@kite-js/core";
import styled from "styled-components";

export const SpanMargin = styled(Span)`
    margin-left: 30px;
    text-align: left;
    .rtl {
        margin-right: 30px;
        text-align: right;
    }
`;

export const CheckBoxMargin = styled(FormCheckbox)`
    + span::after {
        margin-left:6px;
        .rtl {
            margin-right: 6px;
        }
    }
`;