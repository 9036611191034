import { SignupResponse } from "../../Model/SignupResponse";
import { PageType } from "../../constants/common/pageType";
import { SignUpTypes } from "../../constants/common/signUpType";

export default async function signup(signupType: SignUpTypes, emailSubscription: boolean, pageType: PageType): Promise<SignupResponse> {
    const result = await fetch('/api/Signup', { 
        credentials: 'same-origin',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            signupType: signupType,
            emailSubscription: emailSubscription,
            pageType: pageType
        }),
    }).then(response => response.json());
    return result;
}