import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SignIn from "../../SignIn/SignIn";
import './App.css';
import SignUp from "../../Signup/Signup";
import ChangePassword from "../../ChangePassword/ChangePassword";
import OnBoarding from "../../OnBoarding/OnBoarding";
import { DefaultCms } from "../../../../constants/common/DefaultCms";


interface AppProps {
    pageParams: {
        cmsItems: Cms.CmsDictionary,
        locale: string;
        enableOnboarding: boolean;
    }
}

const App: React.FC<AppProps> = (props: AppProps) => {
    const { cmsItems, locale, enableOnboarding } = props.pageParams || {} ;
    const defaultCmsItem = cmsItems || DefaultCms;
    const mainProps = {
        cmsItems,
        locale,
    };
    return (
        <React.StrictMode>
            <BrowserRouter>
                <div className="App" >
                    <Routes>
                        <Route path="/signin" element={<SignIn {...mainProps} enableOnboarding={enableOnboarding} />}/>
                        <Route path="/:culture/signin" element={<SignIn {...mainProps} enableOnboarding={enableOnboarding}/>} />
                        <Route path="/login" element={<SignIn cmsItems={cmsItems} locale={locale} enableOnboarding={enableOnboarding}/>}/>
                        <Route path="/:culture/login" element={<SignIn cmsItems={cmsItems} locale={locale} enableOnboarding={enableOnboarding}/>} />
                        <Route path="/login.html" element={<SignIn cmsItems={cmsItems} locale={locale} enableOnboarding={enableOnboarding}/>}/>
                        <Route path="/:culture/login.html" element={<SignIn cmsItems={cmsItems} locale={locale} enableOnboarding={enableOnboarding}/>} />
                        <Route path="/signup" element={<SignUp {...mainProps} />} />
                        <Route path="/:culture/signup" element={<SignUp {...mainProps}/>} />
                        <Route path="/register.html" element={<SignUp {...mainProps} />} />
                        <Route path="/:culture/register.html" element={<SignUp {...mainProps}/>} />
                        <Route path="/changepassword" element={<ChangePassword {...mainProps} />}/>
                        <Route path="/:culture/changepassword" element={<ChangePassword {...mainProps} />} />
                        <Route path="/onboarding" element={<OnBoarding cmsItems={defaultCmsItem} />}/>
                        <Route path="/:culture/onboarding" element={<OnBoarding cmsItems={defaultCmsItem} />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </React.StrictMode>
    );
} 

export default App;
