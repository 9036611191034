import React from "react"
import { Card, Modal } from "@kite-js/core";
import { useIsMobile } from "../../../hooks/useIsMobile";

interface OwnProps {
    open: boolean;
    children: React.ReactNode;
    testId: string;
}

type Props = OwnProps

const CommonModal: React.FC<Props> = (props: Props) => {
    const isMobile = useIsMobile();
    return <Modal open={props.open}>
        <Card width={isMobile ? "300px" : "600px"} radius="S" textAlign="center" data-testid={props.testId}>
            {props.children}
        </Card>
    </Modal>
}

export default CommonModal;                            