import React, { FC } from "react"
import { Link, Paragraph, Span } from "@kite-js/core";
import { formatMessageJsx } from "../../../utils/formatMessage";
import { CmsIds } from "../../../constants/common/CmsIds";

interface Props {
    cmsItems: Cms.CmsDictionary
}

const SignupTermAndPoicy: FC<Props> = ({ cmsItems }) => {
    const linkElements = [
        (<Link key='link-term' href={"https://partners.agoda.com/Content/pdf/Agoda_Affiliate_Terms_of_Use.pdf"} target="_blank">
            <Span textSize="XS">{cmsItems[CmsIds.Term]}</Span>
        </Link>),
        (<Link key='link-policy' href={"https://partners.agoda.com/Content/pdf/Agoda_Partner_Center_Privacy_Policy.pdf"} target="_blank">
            <Span textSize="XS">{cmsItems[CmsIds.Policy]}</Span>
        </Link>)];
    return <Paragraph textSize="XS">{formatMessageJsx(cmsItems[CmsIds.BySignupTermAndPolicy], linkElements)}</Paragraph>
}

export default SignupTermAndPoicy