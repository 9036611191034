import React from 'react';
import { default as UL, SDKConfig } from '@ul/sdk';
import { Box } from '@kite-js/core';

interface UniversalLoginPageComponentProps {
    sdkConfig: SDKConfig;
}

const UniversalLoginPageComponent: React.FC<UniversalLoginPageComponentProps> = React.memo(({ sdkConfig }: UniversalLoginPageComponentProps) => {

    const parentRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (!parentRef?.current) {
            return;
        }
        const parentId = `universal-login-app-${Math.ceil(Math.random() * 1000)}`;
        parentRef.current.setAttribute('id', parentId);
        return UL.load(`#${parentId}`, sdkConfig);
    }, [parentRef]);

    return (<Box data-section="UniversalLoginComponent" data-testid="UniversalLoginComponent" innerRef={parentRef} />);
});

UniversalLoginPageComponent.displayName = 'UniversalLoginPageComponent';

export default UniversalLoginPageComponent;
