import { SignUpTypes } from '../constants/common/signUpType';

export const parseSignUpType = (psrc: string | null): SignUpTypes => {
    if (!psrc) return SignUpTypes.Affiliate;

    switch (psrc.toLowerCase()) {
        case "partner":
        case "0":
            return SignUpTypes.Affiliate;
        case "internal":
        case "1":
            return SignUpTypes.Enterprise;
        case "3":
        case "business":
            return SignUpTypes.Corporate;
        default:
            return SignUpTypes.Affiliate;
    }
}