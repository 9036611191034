import { SDKConfig } from "@ul/sdk";

export const DefaultUL: SDKConfig =  {
    path: 'signin',
    cultureCode: 'en-us',
    appId: 'partnercenter',
    fullScreen: {
        enable: false,
        withoutWrapper: true,
    },
    iframe: {
        style: {
            'min-width': '500px',
            'min-height': '400px',
            width: '100%',
        },
    },
}
