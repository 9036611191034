import React from "react";
import UniversalLoginPageComponent from "../../Common/UniversalLogin/UniversalLogin";
import { LoginSuccessResponseStatus, SDKConfig } from "@ul/sdk";
import { log } from "../../Utils/logUtils";
import Navbar from "../../Common/Navbar/Navbar";
import { DefaultUL } from "../../../constants/common/DefaultULSdk";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { ULContainer } from "../../Common/Layout/ULContainer.styled";
import { useSearchParams } from 'react-router-dom';

interface Props {
    locale: string;
    cmsItems: Cms.CmsDictionary
}

const ChangePasswordComponent: React.FC<Props> = (props: Props) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('rToken');
    const isMobile = useIsMobile();
    
    const sdkConfig: SDKConfig = {
        ...DefaultUL,
        path: 'changePassword',
        changePasswordToken: token ?? undefined,
        cultureCode: props.locale ?? "en-us",
        iframe: {
            style: {
                'min-width': isMobile ? "315px" : "500px",
                'padding': "0",
                'background': "white",
                "border-radius": "20px"
            },
        },
        eventHandlers: {
            onLoginSuccess: async () => {
                log('onChangePasswordSuccess');
                return {
                    loginStatus: LoginSuccessResponseStatus.Success,
                };
            },
            onLoginComplete: (success: boolean) => {
                if (success) {
                    log('onChangePasswordComplete');
                    window.location.href = '/signin';
                }
            },
        }
    };

    return (
        <ULContainer className="changepassword-container" data-testid="changepassword-container">
            <Navbar cmsItems={props.cmsItems} />
            <UniversalLoginPageComponent sdkConfig={sdkConfig}/>
        </ULContainer>
        
    );
}

export default ChangePasswordComponent;