import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    background-color: #4b80f3;
    background: -webkit-gradient(linear, left top, right top, from(#4b80f3), to(#8c69ca));
    background: linear-gradient(to right, #4b80f3, #8c69ca); 
    font-size: 18px;
    @media only screen and (max-width: 600px) {  
        padding: 20px 10px 20px 0;
        font-size: 16px;
    }
`;

export const NavContainer = styled.div`
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1170px;  
    width: 100%;
    justify-content: space-between;
`;

export const MainMenu = styled.ul`
    display: flex;
    gap: 30px;
    align-items: center;
    color: white;
    font-weight: 500;
    list-style-type: none;
    li {
        cursor: pointer;
    }
    .property-owner .traffic-tracking {
        color: white;
        text-decoration: none;
    }
    @media only screen and (max-width: 600px) {
        gap: 15px;
    }
`;

export const SignInButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: white;
    text-decoration: none;
`;