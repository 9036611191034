import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import reportWebVitals from '../../../reportWebVitals';
import { getPageParams } from '../../../utils/getPageParams';
import { addAppInsights } from '../../Services/appInsights';

addAppInsights();

ReactDOM.render(
    React.createElement(App, {
        pageParams: getPageParams()
    }, null),
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
