import React, { FC } from "react"
import { default as UL, SDKConfig } from "@ul/sdk";
import { ULSignUpContainer } from './UniversalSignup.styled';

interface UniversalSignUpProps {
    sdkConfig: SDKConfig
}

const UniversalSignUp: FC<UniversalSignUpProps> = ({ sdkConfig }) => {
    const parentRef = React.useRef<HTMLDivElement>(null);
 
    React.useEffect(() => {
        if (!parentRef?.current) {
            return;
        }
        const parentId = `universal-sign-up-app-${Math.ceil(Math.random() * 1000)}`;
        parentRef.current.setAttribute('id', parentId);
        return UL.load(`#${parentId}`, sdkConfig);
    }, [parentRef, sdkConfig])

    return <ULSignUpContainer data-section="UniversalSignUpComponent" data-testid="UniversalSignUpComponent" ref={parentRef} />
}

UniversalSignUp.displayName = "UniversalSignUpComponent"

export default UniversalSignUp