import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const addAppInsights = (): void => {
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: "InstrumentationKey=00000000-0000-0000-0000-000000000000;IngestionEndpoint=//aai.agoda.net;LiveEndpoint=//aai.agoda.net;ProfilerEndpoint=//aai.agoda.net;SnapshotEndpoint=//aai.agoda.net;",
            autoTrackPageVisitTime: true,
            disableFetchTracking: false,
            enableAjaxErrorStatusText: true,
            enableAjaxPerfTracking: true,
            enableAutoRouteTracking: true,
        },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags!["ai.cloud.role"] = "partner-center-client";
    });
    appInsights.trackPageView();
};
